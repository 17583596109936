<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Generate Termin" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Pengelola * <span v-if="isLoadingGetPengelola">(loading...)</span></label>
            <vs-select class="w-full" autocomplete v-model="data.id_pengelola" :disabled="isLoadingGetPengelola">
              <vs-select-item v-for="(it, index) in listPengelola" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Generate Untuk *</label>
            <vs-select class="w-full" v-model="data.generate_type">
              <vs-select-item v-for="(it, index) in [{label: 'Spesifik Unit', value: 'SPESIFIK_UNIT'}]" :key="index" :value="it.value" :text="it.label"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-4/12 w-full" v-if="data.generate_type === 'SPESIFIK_UNIT'">
            <label class="ml-1 text-xs">No. Unit *</label>
            <div @click="modalPropertiUnit.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Unit" :value="data.no_unit ? `${data.kode_proyek_properti_unit} | ${data.no_unit}` : ''" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">First Termin Due Date *</label>
            <flat-pickr class="w-full" v-model="data.tgl_start_due"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Jumlah (bulan) *</label>
            <vs-input class="w-full" type="number" v-model="data.jml_termin" :danger="data.jml_termin > 12" danger-text="Maksimal 12 termin."/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Last Termin Due Date</label>
            <vs-input class="w-full" :value="tglEndDue" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider class="mt-base">Item Pekerjaan IPL</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="modalItemIpl.active = true" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-table :data="data.pekerjaans" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Aksi</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Item IPL</vs-th>
            <vs-th class="whitespace-no-wrap">Tarif *</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl.Mulai</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl.Akhir</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>{{ item.nama_item_ipl }}</vs-td>
              <vs-td><v-money class="w-48" v-model="item.tarif"/></vs-td>
              <vs-td><flat-pickr class="w-full" v-model="item.tgl_awal"></flat-pickr></vs-td>
              <vs-td><flat-pickr class="w-full" v-model="item.tgl_akhir"></flat-pickr></vs-td>
              <vs-td><vs-input class="w-full" type="text" v-model="item.keterangan"/></vs-td>
            </vs-tr>

            <!--footer-->
            <vs-tr class="text-sm" v-if="data.length > 0">
              <vs-td class="font-bold" colspan="2">GRAND TOTAL</vs-td>
              <vs-td class="text-left font-bold"><v-money class="w-48" :value="grandTotal" disabled/></vs-td>
              <vs-td class="font-bold" colspan="3"></vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal item ipl-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Item IPL"
                :active="modalItemIpl.active"
                v-on:update:active="modalItemIpl.active = $event">
        <ItemIpl v-if="isActive" :selectable="true" multi-select :disable-ids="itemIplIds" :externalFilter="filterItemIpl" @selected="onSelectedModalItemIpl"/>
      </vs-popup>

      <!--modal properti unit-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Properti Unit"
                :active.sync="modalPropertiUnit.active">
        <PropertiUnit
          v-if="!isFirstActive"
          :selectable="true"
          :externalFilter="filterPropertiUnit"
          @selected="onSelectedModalPropertiUnit"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import IplRepository from '@/repositories/estate/ipl-repository'
import ItemIpl from '@/views/pages/master/item-ipl/ItemIpl'
import PropertiUnit from '@/views/pages/master/properti-unit/PropertiUnit'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import ProyekRepository from '@/repositories/master/proyek-repository'
import moment from 'moment'

export default {
  name: 'TerminAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    PropertiUnit,
    ItemIpl,
    VMoney,
    flatPickr
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isLoadingGetPengelola: false,
      isLoadingGetProyek: false,
      errors: null,
      listPengelola: [],
      modalItemIpl: {
        active: false
      },
      modalPropertiUnit: {
        active: false
      },
      data: {
        generate_type: 'SPESIFIK_UNIT',
        id_pengelola: null,
        id_properti_unit: null,
        status_unit: null,
        tgl_start_due: null,
        jml_termin: 1,
        pekerjaans: []
      }
    }
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getInitData()
        this.isFirstActive = false
      }
    },
    'data.id_properti_unit' (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.onChangePropertiUnit()
      }
    }
  },
  computed: {
    filterItemIpl () {
      return { id_properti_unit: this.data.id_properti_unit }
    },
    filterPropertiUnit () {
      return {
        status_unit: ['BOOKING', 'KONTRAK', 'SP3K', 'AKAD', 'AJB', 'SERAH TERIMA']
      }
    },
    grandTotal () {
      return _.sumBy(this.data.pekerjaans, item => parseFloat(item.tarif || '0'))
    },
    itemIplIds () {
      return _.map(this.data.pekerjaans, item => item.id_item_ipl)
    },
    tglEndDue () {
      if (!this.data.tgl_start_due || !this.data.jml_termin || this.data.jml_termin < 1) return null
      return moment(this.data.tgl_start_due).add(this.data.jml_termin - 1, 'M').format('YYYY-MM-DD')
    }
  },
  methods: {
    getInitData () {
      this.getPengelola()
    },

    getPengelola () {
      this.isLoadingGetPengelola = true

      const params = {
        externalFilter: JSON.stringify({
          is_pengelola_ipl: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.listPengelola = response.data.data
          if (response.data.data.length > 0) {
            this.data.id_pengelola = response.data.data[0].id
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingGetPengelola = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      IplRepository.createTermin(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        id_pengelola: this.data.id_pengelola,
        generate_type: this.data.generate_type,
        id_properti_unit: this.data.id_properti_unit,
        tgl_start_due: this.data.tgl_start_due,
        jml_termin: this.data.jml_termin,
        keterangan: this.data.keterangan,
        pekerjaans: _.map(this.data.pekerjaans, item => {
          return {
            id_item_ipl: item.id_item_ipl,
            tarif: item.tarif,
            tgl_awal: item.tgl_awal,
            tgl_akhir: item.tgl_akhir,
            keterangan: item.keterangan
          }
        })
      }
    },

    addRow (item) {
      const row = {
        uuid: uuid(),
        id_item_ipl: item.id,
        nama_item_ipl: item.nama,
        tarif: item.tarif
      }
      this.data.pekerjaans.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.pekerjaans, item => item.uuid === uuid)
      this.data.pekerjaans.splice(index, 1)
    },

    onChangePropertiUnit () {
      this.data.pekerjaans = []
    },

    onSelectedModalItemIpl (items) {
      _.each(items, item => this.addRow(item))
      this.modalItemIpl.active = false
    },

    onSelectedModalPropertiUnit (item) {
      this.data.id_properti_unit = item.id
      this.data.no_unit = item.no_unit
      this.data.status_unit = item.status_unit
      this.data.kode_proyek_properti_unit = item.kode_proyek
      this.modalPropertiUnit.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['listPengelola'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
